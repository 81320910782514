<template>
  <div class="flex flex-col flex-grow">
    <template>
      <div class="flex-grow mx-auto my-10 px-10 md:px-12">
        <div class="mb-8">
          <h3 class="font-bold mb-2 text-left text-xl">
            Select a Plan
          </h3>
          <p class="text-ash-700 text-left text-12">
            Select a plan to specifiy your preferrence. Interest only
            applies when you use your card.
          </p>
        </div>
        <template v-if="plans.loading">
          <div class="flex flex-col gap-6">
            <skeleton-loader
              class="opacity-10 rounded"
              height="120px"
              width="100%"
            />
          </div>
        </template>
        <template v-else v-for="(p, n) in filteredPlans">
          <div
            class="flex flex-col border mb-6 p-5 rounded cursor-pointer"
            :class="
              plan.data === p
                ? `${plans.colors[p.id - 1].border} ${plans.colors[p.id - 1].bgLight}`
                : ''
            "
            @click.prevent="setPlan(p)"
            :key="n"
          >
            <div class="w-40px mr-2">
              <div
                class="border flex h-30px icon-thick items-center justify-center rounded-50 text-white text-lg w-30px"
                :class="{
                  [`${plans.colors[p.id - 1].bg} ${plans.colors[p.id - 1].border}`]: plan.data === p
                }"
              >
                <ion-icon name="checkmark-sharp" />
              </div>
            </div>
            <span
              class="text-white font-medium text-xs py-2 px-3 rounded-sm -mt-5 ml-auto"
              :class="{ [plans.colors[p.id - 1].bg]: true }"
            >
              {{ p.plan_name }}
            </span>
            <div class="mr-2">
              <div class="flex items-end text-xs">
                <div class="mr-2">₦ {{ p.default_fee | currency }}</div>
                <div class="font-light text-xs opacity-50">
                  annual fee
                </div>
              </div>
              <div class="flex items-end mt-1">
                <div class="font-bold mr-2">{{ p.interest }}%</div>
                <div class="font-light text-xs opacity-50">
                  monthly interest
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="getFormError(subscription)">
          <div class="alert alert-red-soft">
            <span class="alert-icon mr-3">!</span>
            <span class="text-xs font-normal">{{ getFormError(subscription) }}</span>
          </div>
        </template>

      </div>

      <div
        class="
          bg-white flex flex-col gap-4 items-center justify-center
          h-150px p-5 relative rounded-b w-full
          sm:pr-4 sm:pl-5 md:h-120px
        "
      >
        <div class="absolute bg-blue-200 h-2px left-0 top-0 w-full">
          <div
            class="bg-blue-500 h-full"
            :style="{ width: progressWidth }"
          />
        </div>

        <div
          class="flex flex-col gap-4 items-center justify-center lg:flex-row"
        >
          <button
            class="bg-blue-700 border-blue-700 btn btn-blue btn-md"
            @click.prevent="payLater"
            :disabled="!plan.data.plan_name || subscription.loading"
          >
            <sm-loader-white v-if="subscription.loading && !isContinue" />
            <span v-else>
              Pay Later
            </span>
          </button>
          <button
            v-if="cardRoute === 'card'"
            class="btn btn-blue btn-md relative"
            id="payNowBtn"
            @click.prevent="verifySubscription(false)"
            :disabled="!plan.data.plan_name || subscription.loading"
          >
            <div
              class="absolute bg-teal-500 font-normal left-0 -ml-1 px-2 py-1 rounded-xs text-white -top-8 z-10"
              id="floatBtn"
              style="background-color: #0CD5A4; font-size: 8px;"
            >
              20% OFF
            </div>
            <sm-loader-white v-if="subscription.loading && isContinue" />
            <span v-else>
              Pay Now - ₦ {{ planFee | currency }}
            </span>
          </button>
        </div>

        <paystack
          :amount="fee"
          class="hidden"
          :callback="completeSubscription"
          ref="subscribeButton"
        >
          {{ subscription.loading ? "Verifying..." : "Finish" }}
        </paystack>

        <div
          class="text-sm opacity-25 font-light flex items-center justify-center"
        >
          <ion-icon name="lock-closed-outline" class="text-xl mr-2" />
          Secure Payment
        </div>
      </div>
    </template>

    <modal
      className="text-center pt-10 w-full md:w-443px lg:w-443px"
      ref="payLaterModal"
    >
      <div class="px-8">
        <vector
          :src="assets.icons.sm.checkmarkCircle"
          class="flex justify-center mb-5"
        />
        <div class="text-center text-2xl font-bold mb-10">Attention!</div>
        <div class="font-light text-12 text-ash-700 mb-10 leading-normal">
          The payment for this plan will be added to your outstanding balance
          when you start using your card.
        </div>
      </div>
      <div class="flex mb-10 w-full">
        <div
          class="w-1/10 text-blue-400 bg-white alert-ash-soft text-left"
          style="height: 16px; width: 16px"
        >
          <div
            class="bg-gray-200 text-center"
            style="border-radius: 50%; font-size: 10px"
          >
            <span class="text-blue-500 font-bold">!</span>
          </div>
        </div>
        <div class="w-9/10 pl-1">
          <p class="text-left text-ash-700 text-11">
            You would be charged N{{ 250 | currency }} to set up your card now.
          </p>
        </div>
      </div>

      <template v-if="getFormError(subscription)">
        <div class="alert alert-red-soft mb-6">
          <span class="alert-icon mr-3">!</span>
          <span class="text-xs font-normal">{{ getFormError(subscription) }}</span>
        </div>
      </template>

      <button
        class="btn btn-blue btn-md"
        @click.prevent="verifySubscription(true)"
        :disabled="subscription.loading"
      >
        <sm-loader-white v-if="subscription.loading" />
        <span v-else>Pay Now - ₦250</span>
      </button>
    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Subscription',
  data() {
    return {
      defer: false,
      isContinue: true,
      plan: this.$options.resource({}),
      planFee: 0,
      plans: this.$options.resource([], {
        colors: [
          {
            bg: "bg-purple-500",
            bgLight: "bg-purple-100",
            border: "border-purple-500",
          },
          {
            bg: "bg-blue-500",
            bgLight: "bg-blue-100",
            border: "border-blue-500",
          },
          {
            bg: "bg-blue-800",
            bgLight: "bg-blue-100",
            border: "border-blue-800",
          },
          {
            bg: "bg-pink-600",
            bgLight: "bg-pink-100",
            border: "border-pink-600",
          },
        ],
        verified: true,
        failed: false,
        success: false,
      }),
      progress: 50,
      subscription: this.$options.basicForm([], {
        verified: true,
        failed: false,
        success: false,
      }),
    };
  },
  computed: {
    // I will make this more dynmic in the near future. 😉
    canAccessPrestigePlan() {
      return this.user?.profile?.['credit_limit'] > 100000;
    },
    cardRoute() {
      return this.$store.state.session.configuration.card_route;
    },
    fee() {
      if (this.defer) {
        // return this.plan.data?.default_fee
        return 250;
      }
      return this.plan.data.default_fee - this.plan.data.default_fee * 0.2;
    },
    filteredPlans() {
      return this.plans.data?.filter((plan) => plan.plan_name !== 'Prestige' || this.canAccessPrestigePlan);
    },
    progressWidth() {
      return `${this.progress}%`; // w-7/10
    }
  },
  beforeMount() {
    this.increaseProgress();
    this.getPlans();
  },
  methods: {
    ...mapActions('session', ['updateUser']),

    close() {
      this.$emit('close');
    },
    increaseProgress() {
      setTimeout(() => {
        this.progress = 75;
      }, 10);
    },
    payLater() {
      this.googleEvent("Card request", "pay_later");
      this.$refs.payLaterModal.open();
    },
    setPlan(plan) {
      // this.plan.setValue(this.plans[plan]);
      this.planFee = plan.default_fee;
      this.plan.setValue(this.plans.data.find((p) => p.id == plan.id));
    },
    async completeSubscription(data) {
      this.$refs.payLaterModal.close();

      var { reference } = data;

      this.subscription.setLoading();

      await this.sendRequest('personal.subscription.subscribe', {
        data: {
          planId: this.plan.data.id,
          reference,
          type: this.defer ? "defer" : "pay",
        },
        success: () => {
          this.loadResource('personalCards', true);
          this.updateUser({ card_request: {} });
          this.$emit('next');
        },
        error: (error) => {
          this.subscription.error = error;
        },
      });

      this.subscription.setLoading(false);
    },
    async getPlans() {
      this.plans.setLoading();

      await this.sendRequest('personal.subscription.getPlans', {
        success: (response) => {
          response;
          this.plans.setValue(response.data.plans);
          this.$emit('update-plans', this.plans.data);
        },
        error: (error) => {
          this.plans.error = error;
        },
      });

      this.plans.setLoading(false);
    },
    async verifySubscription(deferred) {
      this.googleEvent("Card request", "pay_now");
      if (deferred) {
        this.isContinue = false;
        this.defer = true;
      }

      this.subscription.setLoading();

      await this.sendRequest('personal.subscription.verifyAccount', {
        success: () => {
          this.subscription.verified = true;
          this.$refs.subscribeButton.$el.click();
        },
        error: (error) => {
          if (error.response?.status == 412) {
            this.subscription.failed = true;
            this.$refs.payLaterModal.close();
            this.close();

            this.$error({
              title: 'Can\' Do That Yet',
              body: error.response?.data?.data,
              button: {
                text: 'Okay',
              }
            });

            // this.$error({
            //   title: 'Upload Your Bank Statement',
            //   body: 'Your bank statement is required to verify and approved your request.',
            //   button: {
            //     text: 'Take Me There',
            //     onClick: () => {
            //       this.$router.push({
            //         name: 'settings-profile',
            //         query: { tab: 'documents' },
            //       });
            //     }
            //   }
            // });
          } else {
            this.subscription.error = error;
          }
        },
      });

      this.subscription.setLoading(false);
    },
  },
};
</script>
